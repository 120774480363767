import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./style.supply-chain.scss"
import WorldDesktop from "/static/products/supply-chain/world_text_desktop@2x.png"
import SassIcon from "/static/products/supply-chain/sass_icon@2x.png"
import SettingIcon from "/static/products/supply-chain/setting_icon@2x.png"
import RobotIcon from "/static/products/supply-chain/robot_icon@2x.png"

const Zmm = () => {
  return (
    <Layout>
      <Seo
        title="可持续供应链_智能决策解决方案-蓝胖智汇"
        keywords="可持续供应链,智能决策解决方案"
        description="蓝胖智汇专注可持续供应链、智能决策解决方案，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../../../images/products-banner.png"
          alt="可持续供应链_智能决策解决方案-蓝胖智汇"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          产品列表
        </div>
      </div>
      <div className="supply-chain">
        <div className="case-wrapper">
          <div id="leading-image">
            <h1 className="title">蓝胖智汇供应链解决方案</h1>
            <p>
              蓝胖智汇供应链解决方案针对企业供应链流通环节的完整业务流程，基于时间空间多目标优化引擎与多维度大数据洞察，对上下游多个作业环节进行全局统筹与规划，打通生产、装卸、运输、仓储、配送等多个场景，为客户建立业务导向型的智能运营管理平台。
            </p>
          </div>
        </div>
        <div className="application-scenario">
          <div className="title">应用场景</div>
          <p>
            蓝胖智汇供应链解决方案打破传统物流IT系统信息孤岛，针对客户“端到端”业务流程进行统一建模和全局多目标优化计算，为客户寻找优化业务流程的最佳策略方案。例如，在安排生产计划时，考虑成品运输车次；在货物装载上车时，考虑多点配送路线；在收货入仓时，考虑订单配货频次等。
          </p>
        </div>
        <div className="application-bg">
          <img
            sizes="(max-width: 680px) 1500px, 3840px"
            src={WorldDesktop}
            alt=""
          />
        </div>
        <div className="deploy">
          <div className="title">部署方式</div>
          <div className="row">
            <img src={SassIcon} alt="" />
            <div className="text">
              采用非侵入式SaaS部署方式，无需对企业既有物流信息系统进行改造或替换。
            </div>
          </div>
          <div className="row">
            <div className="text">
              用分散在各业务系统（WMS, TMS, OMS,
              ERP）中已有的数据，以业务流程为核心视角，对业务上下游多个环节进行垂直优化。
            </div>
            <img src={SettingIcon} alt="" />
          </div>
          <div className="row">
            <img src={RobotIcon} alt="" />
            <div className="text">
              可搭配蓝胖子旗下各型智能物流机器人和其他软件服务，为客户提供一站式供应链综合优化升级服务
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Zmm
